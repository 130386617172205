import React from "react";
import Image, { ImageProps } from "next/image";
import Link from "next/link";
import { twMerge } from "tailwind-merge";

interface CardProps {
  title: string;
  description: string;
  ctaText: string;
  ctaOnClick?: React.MouseEventHandler<HTMLInputElement>;
  image?: ImageProps;
  isFullWidth?: boolean;
  classNames?: string;
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  ctaText,
  ctaOnClick,
  image,
  isFullWidth = false,
  classNames,
}) => {
  return (
    <div
      className={twMerge(
        "flex flex-col justify-between w-full h-full max-h-full md:min-h-60 md:space-y-0 rounded-2xl border-1 border-shadow-color bg-white dark:bg-background-color shadow-full",
        classNames,
        isFullWidth ? "md:flex-row" : ""
      )}
    >
      <div
        id="card_text_section"
        className={twMerge(
          "flex flex-col p-10 md:py-20 md:space-y-10",
          image ? "md:basis-1/2" : ""
        )}
      >
        <div id="card_text_section_title_and_description">
          <h5 className="text-black dark:text-white">{title}</h5>
          <p className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold text-gray">
            {description}
          </p>
        </div>
        <p className="underlined-link w-fit font-semibold text-black dark:text-white">
          <Link itemProp="email" href="mailto:info.clockami@gmail.com">
            {ctaText} {">"}
          </Link>
        </p>
      </div>
      {image && (
        <div
          id="card_image_section"
          className={twMerge(
            "flex justify-end items-end w-full md:pl-0",
            image ? "md:basis-1/2" : ""
          )}
        >
          <Image {...image} {...{ alt: image.alt as string }} />
        </div>
      )}
    </div>
  );
};

export default Card;
