import React from "react";
import Image from "next/image";
import Link from "next/link";
import { twMerge } from "tailwind-merge";
import Button from "./Button";

interface PricingCardProps {
  price: number;
  maxEmployees: number;
  classNames?: string;
}

const PricingCard: React.FC<PricingCardProps> = ({
  price,
  maxEmployees,
  classNames,
}) => {
  return (
    <div
      className={twMerge(
        "flex flex-col p-5 py-10 w-80 min-h-60 space-y-10 bg-white dark:bg-background-color rounded-xl border-1 border-shadow-color dark:shadow-white shadow-full",
        classNames
      )}
    >
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row justify-center items-center space-x-2">
          <p className="text-5xl font-bold dark:text-white">{price}€</p>
          <div className="flex flex-col text-gray">
            <p>/al mese + IVA</p>
            <p>/per dipendente</p>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center space-x-2">
          <p className="text-gray">
            fino a <span className="font-semibold">{maxEmployees}</span>{" "}
            dipendenti
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <Button
          id={`pricing_inizia_ora_${price}€`}
          text={"Inizia ora"}
          href={"/"}
          classNames="font-medium w-40"
        />
      </div>
    </div>
  );
};

export default PricingCard;
