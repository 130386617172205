import React, { useState } from "react";

export interface ToggleProps {
  options: string[];
  defaultOptionIndex?: number;
  onToggle: (isChecked: boolean) => void;
  classNames?: string;
}

const Toggle: React.FC<ToggleProps> = ({
  options,
  defaultOptionIndex = 0,
  onToggle,
  classNames = "",
}) => {
  const [isChecked, setIsChecked] = useState(true);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onToggle(isChecked);
  };
  return (
    <div className="flex flex-row items-center space-x-4">
      <p className="dark:text-white">{options[0]}</p>
      <label
        id="toggle_id"
        className="flex w-fit cursor-pointer select-none items-center"
      >
        <div className="relative w-fit">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="sr-only"
            aria-labelledby={"toggle_id"}
          />
          <div
            className={`box block h-8 w-14 rounded-full ${
              isChecked ? "bg-primary" : "bg-gray"
            }`}
          ></div>
          <div
            className={`absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white transition ${
              isChecked ? "translate-x-full" : ""
            }`}
          ></div>
        </div>
      </label>
      <p className="dark:text-white">{options[1]}</p>
    </div>
  );
};

export default Toggle;
