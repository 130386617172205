import React from "react";
import Link from "next/link";
import { twMerge } from "tailwind-merge";
import type { Url } from "next/dist/shared/lib/router/router";

export interface ButtonProps {
  id: string;
  text: string;
  href: Url;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  classNames?: string;
}

const Button: React.FC<ButtonProps> = ({
  id,
  text,
  href,
  onClick,
  classNames = "",
}) => {
  const gEvent = ({ action, category, label, value }: any) => {
    (window as any).gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  };

  const addEvent = () => {
    gEvent({
      action: id,
      category: "",
      label: "",
      value: "",
    });
  };

  const handleButtonClick = () => {
    addEvent();
    onClick;
  };
  return (
    <Link
      id={id}
      href={href}
      onClick={handleButtonClick}
      className={twMerge(
        "flex w-auto h-10 justify-center items-center bg-primary text-white px-4 py-2 rounded-2xl cursor-pointer border-2 border-primary transition-colors duration-300 ease-in-out hover:bg-transparent hover:text-primary",
        classNames
      )}
    >
      {text}
    </Link>
  );
};

export default Button;
