"use client";
import React, { ReactNode, useEffect, useState } from "react";
import Image from "next/image";
import arrowDown from "../public/arrow-down.svg";
import { twMerge } from "tailwind-merge";

export interface FAQDropdownProps {
  title: string;
  children: ReactNode;
  def?: "open" | "close";
  classNames?: string;
}

const FAQDropdown: React.FC<FAQDropdownProps> = ({
  title,
  children,
  def = "close",
  classNames = "",
}) => {
  const defaultOption = def == "open" ? true : false;
  const [isOpen, setIsOpen] = useState(defaultOption);
  const handleBoxClick = () => {
    setIsOpen(!isOpen);
  };

  const [contentDivHeight, setContentDivHeight] = useState(0);

  useEffect(() => {
    const contentDiv = document.getElementById("FAQDropdown_Content");
    if (contentDiv) {
      setContentDivHeight(contentDiv.clientHeight);
    }
  }, []);

  return (
    <div className="flex flex-col p-5 rounded-2xl transition-colors hover:bg-primary-100 dark:hover:bg-background-color card">
      <div
        className="flex flex-row items-center justify-between hover:cursor-pointer select-none will-change-auto"
        onClick={handleBoxClick}
      >
        <h5 className="dark:text-white">{title}</h5>
        <Image
          src={arrowDown}
          width={20}
          height={20}
          alt={"Arrow toggle FAQ section"}
          className={`transition-all duration-100 ${
            !isOpen ? "-rotate-90" : ""
          }`}
        />
      </div>
      <div
        className={"transition-height duration-500 overflow-hidden"}
        style={{ height: !isOpen ? 0 : contentDivHeight }}
      >
        <div id="FAQDropdown_Content" className="h-fit pt-2">
          {children}
        </div>
      </div>
    </div>
  );
};

export default FAQDropdown;
