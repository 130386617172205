"use client";
import Image from "next/image";
import PricingCard from "../../components/PricingCard";
import Toggle from "../../components/Toggle";
import { useEffect, useState } from "react";
import FAQDropdown from "../../components/FAQDropdwon";
import Card from "../../components/Card";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Head from "next/head";

gsap.registerPlugin(ScrollTrigger);

interface PricingModelProps {
  price: number;
  maxEmployees: number;
}

export default function Home() {
  const monthlyPrices = [
    {
      price: 0,
      maxEmployees: 10,
    },
    {
      price: 3.99,
      maxEmployees: 30,
    },
    {
      price: 2.99,
      maxEmployees: 100,
    },
  ];
  const annualyPrices = [
    {
      price: 0,
      maxEmployees: 10,
    },
    {
      price: 2.99,
      maxEmployees: 30,
    },
    {
      price: 1.99,
      maxEmployees: 100,
    },
  ];

  const FAQs = [
    {
      title:
        "Che cos'è il software di gestione del personale e come può aiutare la mia azienda?",
      content:
        "Il nostro software di gestione del personale è una soluzione completa che ti consente di <span style='font-weight: 600'>monitorare e gestire le presenze dei dipendenti</span>, le <span style='font-weight: 600'>ferie</span> e i <span style='font-weight: 600'>permessi</span> in modo efficiente. Con il nostro HR portal, puoi semplificare l'organizzazione dei turni di lavoro e avere tutto sotto controllo, migliorando la produttività e riducendo i costi operativi.",
    },
    {
      title: "Come funziona la rilevazione delle presenze dei dipendenti?",
      content:
        "La nostra <span style='font-weight: 600'>app di rilevazione presenze</span> permette ai dipendenti di registrare facilmente la loro presenza con un solo click. Ogni mattina, i dipendenti riceveranno un'email con un link per confermare la loro <span style='font-weight: 600'>presenza giornaliera</span>. Questo sistema di registrazione presenze è semplice e intuitivo, migliorando la puntualità e l'accuratezza delle registrazioni.",
    },
    {
      title: "Il vostro programma di gestione presenze è davvero gratuito?",
      content:
        "Sì, offriamo una <span style='font-weight: 600'>versione gratuita</span> del nostro programma per la gestione delle presenze che include tutte le funzionalità di base necessarie per le piccole e medie imprese. Questa versione ti permette di gestire <span style='font-weight: 600'>turni di lavoro</span>, <span style='font-weight: 600'>ferie</span> e <span style='font-weight: 600'>permessi</span> senza alcun costo iniziale, rendendo il nostro software una delle soluzioni più economiche disponibili sul mercato.",
    },
    {
      title:
        "Posso gestire i turni di lavoro dei dipendenti con il vostro software?",
      content:
        "Assolutamente! Il nostro software per turni di lavoro è progettato per facilitare la creazione e la gestione dei turni di lavoro. Puoi creare e modificare i turni in pochi clic, garantendo una pianificazione flessibile e ottimizzata per le esigenze della tua azienda.",
    },
    {
      title:
        "È possibile accedere al software di gestione del personale da remoto?",
      content:
        "Sì, il nostro sistema è basato su <span style='font-weight: 600'>cloud</span>, il che significa che puoi accedere al programma di gestione aziendale da qualsiasi luogo e dispositivo connesso a Internet. Questa funzionalità di <span style='font-weight: 600'>dipendenti in cloud</span> ti permette di gestire il personale in modo efficiente anche quando sei fuori ufficio.",
    },
    {
      title:
        "Come posso iniziare a utilizzare il vostro software di gestione presenze?",
      content:
        "Per iniziare a utilizzare il nostro <span style='font-weight: 600'>software di gestione presenze</span>, basta registrarsi sul nostro sito web e seguire le istruzioni per configurare il tuo account. La nostra interfaccia user-friendly ti guiderà attraverso tutto il processo, rendendo facile e veloce l'integrazione del sistema nella tua azienda.",
    },
    {
      title:
        "Quali sono i vantaggi di utilizzare un HR portal per la gestione del personale?",
      content:
        "Un <span style='font-weight: 600'>HR portal</span> centralizzato offre numerosi vantaggi, tra cui una migliore organizzazione dei dati dei dipendenti, accesso rapido alle informazioni critiche, e una gestione semplificata delle presenze e dei turni. Inoltre, consente di ridurre gli errori manuali e di migliorare la comunicazione interna, aumentando così l'efficienza operativa.",
    },
  ];

  const [prices, setPrices] = useState<PricingModelProps[]>(annualyPrices);
  const togglePricesModel = (isChekced: boolean) => {
    setPrices(isChekced ? monthlyPrices : annualyPrices);
  };

  // useEffect(() => {
  //   const cardElements = document.querySelectorAll(".card");
  //   let mm = gsap.matchMedia();
  //   mm.add("(min-width: 800px)", () => {
  //     cardElements.forEach((card) => {
  //       gsap.fromTo(
  //         card,
  //         { opacity: 0, y: 50 },
  //         {
  //           opacity: 1,
  //           y: 0,
  //           duration: 1,
  //           scrollTrigger: {
  //             trigger: card,
  //             start: "top 85%", // inizio dell'animazione quando l'elemento entra nella viewport
  //             toggleActions: "play none none none",
  //           },
  //         }
  //       );
  //     });
  //   });
  // }, []);

  return (
    <main className="w-full h-full">
      <Head>
        <link rel="canonical" href="https://clockami.it/" />
        {/* Open Graph / Facebook Meta Tags */}
        <meta
          property="og:title"
          content="Software di Gestione del Personale - Clockami"
        />
        <meta
          property="og:description"
          content="Digitalizza le presenze e semplifica la gestione del personale con Clockami. Offriamo soluzioni efficienti per monitorare e gestire le presenze dei dipendenti, ferie e permessi."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.clockami.com" />
        <meta
          property="og:image"
          content="https://www.clockami.com/path-to-your-image.jpg"
        />
        <meta property="og:site_name" content="Clockami" />
        <meta property="og:locale" content="it_IT" />
        {/* Schema.org structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            publisher: {
              "@type": "Organization",
              name: "Clockami",
              logo: {
                "@type": "ImageObject",
                url: "https://clockami.it/clockami_logo.png",
              },
            },
            mainEntity: {
              "@type": "FAQPage",
              mainEntity: FAQs.map((faq) => ({
                "@type": "Question",
                name: faq.title,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: faq.content.replace(
                    /<span style='font-weight: 600'>(.*?)<\/span>/g,
                    "$1"
                  ),
                },
              })),
            },
          })}
        </script>
      </Head>
      <div className="flex flex-col mb-10 space-y-10 max-w-[100rem]">
        {/* Hero section */}
        <div className="flex flex-row md:flex-row mb-10 space-y-5">
          <div className="flex relative w-full h-96 items-center justify-center">
            <div className="flex w-full justify-center z-[3]">
              <div className="flex flex-col mx-5 text-center md:text-start md:justify-start space-y-5 md:-ml-20 md:w-2/3 md:my-auto">
                <h1 className="text-black dark:text-white">
                  Domina la Gestione del Tempo con{" "}
                  <span className="text-primary">Clockami</span>
                </h1>
                <div className="w-full">
                  <p className="text-gray">
                    Digitalizza le presenze e semplifica la gestione del
                    personale.
                  </p>
                </div>
              </div>
            </div>
            <div
              id="Blurred_circle"
              className="flex absolute bg-white dark:bg-background-body-color w-[40rem] h-[40rem] rounded-full z-[2] blur-[5rem]"
            ></div>
            <div className="absolute right-0 z-1">
              <Image
                src={"/Meteor.svg"}
                alt={"Meteor"}
                width={700}
                height={500}
              />
            </div>
          </div>
        </div>
        {/* Cards section */}
        <div className="flex justify-center items-center w-full">
          <div className="flex flex-col justify-center space-y-5 w-11/12">
            <Card
              title={"Timbrature Istantanee"}
              description={
                "Non sprecare più minuti preziosi. Il tuo team può timbrare l'entrata e l'uscita in un attimo, mantenendo alta la produttività e riducendo al minimo i problemi."
              }
              ctaText={"Richiedi una Demo"}
              image={{
                src: "/screenshot_homepage.png",
                alt: "",
                width: 600,
                height: 600,
                className: "rounded-2xl",
              }}
              classNames="z-[2]"
              isFullWidth
            />
            <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5">
              <Card
                title={"Richieste di Permesso Semplici"}
                description={
                  "Ottimizza le richieste di ferie e di permessi. Riduci la burocrazia e mantieni registri chiari e organizzati."
                }
                ctaText={"Scopri di più"}
                classNames="md:h-[26rem] card"
              />
              <Card
                title={"Potenza e Semplicità"}
                description={
                  "Progettato per la velocità e la facilità, Clokami funziona in background senza interruzioni."
                }
                ctaText={"Scopri di più"}
                classNames="md:h-[26rem] card"
              />
            </div>
          </div>
        </div>

        {/* Free banner section */}
        <div className="flex relative w-full h-[30rem] items-center justify-center p-9">
          <div className="flex flex-col w-11/12 justify-center space-y-5 dark:text-white z-[3]">
            <h2>
              Gratis per i Primi <span className="text-primary">10</span> Utenti
            </h2>
            <p>
              Trasforma la gestione del tuo personale senza costi. Scopri la
              potenza di Clockami oggi, gratis per i tuoi primi utenti.
            </p>
          </div>
          <div className="absolute left-0 -top-10 z-[2]">
            <Image
              src={"/curve_line.svg"}
              alt={"Curve line"}
              width={450}
              height={450}
              className="w-60 md:w-96"
            />
          </div>
        </div>

        {/* Two more cards */}
        <div className="flex relative w-full justify-center">
          <div className="flex flex-col md:flex-row w-11/12 justify-center space-y-5 md:space-y-0 md:space-x-5">
            <Card
              title={"Notifiche Intelligenti"}
              description={
                "Mai più timbrature dimenticate. Clockami invia promemoria via email, permettendo ai dipendenti di timbrare con un solo clic."
              }
              ctaText={"Scopri di più"}
              image={{
                src: "/screenshot_homepage.png",
                alt: "",
                width: 600,
                height: 600,
                className: "rounded-2xl",
              }}
              classNames="z-[3] card"
            />
            <Card
              title={"Accessibile da Mobile"}
              description={
                "Utilizza Clockami da qualsiasi dispositivo mobile. La piattaforma è ottimizzata per un uso intuitivo ovunque ti trovi."
              }
              ctaText={"Scopri di più"}
              image={{
                src: "/phones_image.png",
                alt: "",
                width: 600,
                height: 600,
                className: "rounded-2xl",
              }}
              classNames="z-[3] card"
            />
          </div>
          <div
            id="blurred_circle"
            className="absolute right-0 -bottom-20 bg-primary w-60 h-60 md:w-96 md:h-96 rounded-full blur-[10rem]"
          ></div>
        </div>

        {/* Pricing section */}
        <div className="flex flex-col justify-center space-y-10">
          <div className="flex flex-col justify-center items-center space-y-3 w-full">
            <hr className="w-3/4 max-w-96 bg-gray" />
            <p className="text-gray">Prezzi</p>
          </div>

          <h3 className="text-center text-5xl md:text-5xl dark:text-white">
            Trasparenza, Affidabilità
          </h3>
          <div className="flex justify-center">
            <Toggle
              options={["Mensile", "Annuale"]}
              onToggle={togglePricesModel}
            />
          </div>
          <div className="flex flex-col md:flex-row w-full justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
            {prices.map((priceModel: PricingModelProps, index) => (
              <PricingCard
                key={index}
                price={priceModel.price}
                maxEmployees={priceModel.maxEmployees}
                classNames="md:w-96 "
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center space-y-10">
          <div className="flex flex-col justify-center items-center space-y-3 w-full">
            <hr className="w-3/4 max-w-96 bg-gray" />
            <p className="text-gray">FAQ</p>
          </div>
          <h2 className="text-center text-5xl md:text-5xl dark:text-white">
            Domande frequenti
          </h2>
          <div className="flex flex-col w-11/12 md:w-3/4 space-y-4">
            {FAQs.map((faq, index) => (
              <FAQDropdown key={index} title={faq.title}>
                <p
                  className="dark:text-white"
                  dangerouslySetInnerHTML={{ __html: faq.content }}
                ></p>
              </FAQDropdown>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}
